import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import React, { Component, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import Subscribe from "src/components/Subscribe";
import { submitAppointment } from "src/store";
import { connect } from "react-redux";
import { makeAnAppointmentProps } from "src/interfaces";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  validationSchema,
  initialValues,
  validationSchema2,
  initialValues2,
} from "./constants";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface IndexState {
  sliderValue: number;
  slider1Value: number;
}

export class Index extends Component<
  { submitAppointment: (payload: makeAnAppointmentProps) => void },
  IndexState
> {
  constructor(props) {
    super(props);

    this.state = {
      sliderValue: 50,
      slider1Value: 50,
    };
  }

  componentDidMount() {
    this.moveDivisor();
    this.moveDivisor1();
  }

  moveDivisor() {
    const handle = document.getElementById("handle");
    const divisor = document.getElementById("divisor");

    if (handle && divisor) {
      handle.style.left = this.state.sliderValue + "%";
      divisor.style.width = this.state.sliderValue + "%";
    }
  }

  moveDivisor1() {
    const handle1 = document.getElementById("handle1");
    const divisor1 = document.getElementById("divisor1");

    if (handle1 && divisor1) {
      handle1.style.left = this.state.slider1Value + "%";
      divisor1.style.width = this.state.slider1Value + "%";
    }
  }

  handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    this.setState(
      (prevState) => ({
        ...prevState,
        sliderValue: value,
      }),
      () => {
        this.moveDivisor();
      }
    );
  };

  handleSlider1Change = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    this.setState(
      (prevState) => ({
        ...prevState,
        slider1Value: value,
      }),
      () => {
        this.moveDivisor1();
      }
    );
  };

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    this.props.submitAppointment({
      ...values,
      callback: () => {
        setSubmitting(false);
      },
    });
  };

  render() {
    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-prev"
          onClick={onClick}
          style={{
            left: "10px",
            zIndex: 1,
            width: "3%",
            borderRadius: "61%",
            height: "8%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-prev-icon " aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
      );
    };

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-next"
          onClick={onClick}
          style={{
            right: "10px",
            zIndex: 1,
            width: "3%",
            borderRadius: "61%",
            height: "8%",
            margin: "auto",
          }}
        >
          <span
            className="carousel-control-next-icon corosi"
            aria-hidden="true"
          />
          <span className="visually-hidden">Next</span>
        </button>
      );
    };
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };
    return (
      <div>
        <Helmet>
          <title>
            Professional Car Detailing in Calgary AB - T-Bros Auto Detailing
          </title>
          <meta
            name="description"
            content="Looking for the best vehicle detailing in Calgary AB? Look no further than T-Bros Auto Detailing. Experience superior quality and affordability. Call us today!"
          />
          <meta
            name="keywords"
            content="car detailing in calgary ab, auto detailing in calgary ab, Professional car detailing in calgary ab, vehicle detailing in calgary ab, Affordable auto detailing in calgary ab, best auto detailing in calgary ab, car detailing experts in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <div>
          <section className="top-section">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="banner-text">
                    <h2 className="h2">15% off</h2>
                    <p>
                      ON ALL SERVICES, WHEN YOU{" "}
                      <Link className="link" to="/booking">
                        BOOK ONLINE
                      </Link>{" "}
                    </p>
                    <h3>GET A CALL BACK</h3>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={this.handleSubmit}
                    >
                      <Form className="form-input">
                        <Field type="text" name="name" placeholder="Name" />
                        <div style={{ height: "18px" }}>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="homeError"
                          />
                        </div>

                        <Field
                          as="select"
                          name="service"
                          className="sected45"
                        >
                          <option value="">Choose Service</option>
                          <option value="Interior Detailing">
                            Interior Detailing
                          </option>
                          <option value="Exterior Detailing">
                            Exterior Detailing
                          </option>
                          <option value="Car Polishing">Car Polishing</option>
                          <option value="Touch-Up Paint">
                            Touch-Up Paint
                          </option>
                          <option value="Unercoating or Rust Protection">
                            Undercoating/Rust Protection
                          </option>
                          <option value="Headlight Restoration">
                            Headlight Restoration
                          </option>
                        </Field>
                        <div style={{ height: "18px" }}>
                          <ErrorMessage
                            name="service"
                            component="div"
                            className="homeError"
                          />
                        </div>

                        <Field
                          type="number"
                          name="phone"
                          maxLength="14"
                          placeholder="Phone"
                        />
                        <div style={{ height: "18px" }}>
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="homeError"
                          />
                        </div>

                        <Field
                          as="textarea"
                          name="message"
                          placeholder="Message ..."
                        />

                        <div className="link-btn">
                          <button type="submit" className="btnform">
                            CALL ME
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="text-background">
                    <h3>TURN HEADS, AUTO DETAILING SERVICES </h3>
                    <h1>THAT DAZZLES!</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="four-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card-img">
                  <img
                    className="img-fluid"
                    alt="deep clean car service Calgary"
                    src="/assets/img/secondary.webp"
                  />
                  <h2
                    style={{
                      backgroundColor: "var(--yellow)",
                      color: "var(--black)",
                    }}
                  >
                    INTERIOR DETAILING
                  </h2>
                  <div className="hovertext">
                    <Link to="/interior-detailing">
                      <p>
                        We offer top-notch interior detailing services, ensuring
                        your vehicle's interior is pristine and refreshed.
                      </p>
                      <i className="bi bi-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card-img">
                  <img
                    className="img-fluid"
                    alt="exterior car wrap"
                    src="assets/img/secondaryd.webp"
                  />
                  <h2 style={{ backgroundColor: "var(--blue)" }}>
                    EXTERIOR DETAILING
                  </h2>
                  <div className="hovertext">
                    <Link to="/exterior-detailing">
                      <p>
                        Unleash the ultimate exterior glow for your vehicle with
                        our expert exterior detailing services in Calgary.
                      </p>
                      <i className="bi bi-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card-img">
                  <img
                    className="img-fluid"
                    alt="car polishing calgary"
                    src="assets/img/wrapping.webp"
                  />
                  <h2
                    style={{
                      backgroundColor: "var(--yellow)",
                      color: "var(--black)",
                    }}
                  >
                    CAR POLISHING
                  </h2>
                  <div className="hovertext">
                    <Link to="/car-polishing">
                      <p>
                        Car polishing services in Calgary involve a technique
                        that improves a vehicle's exterior by removing
                        imperfections and restoring its shine.
                      </p>
                      <i className="bi bi-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card-img">
                  <img
                    className="img-fluid"
                    alt=""
                    src="assets/img/window.png"
                  />
                  <h2 style={{ backgroundColor: "var(--blue)" }}>
                    PAINT PROTECTION FILM
                  </h2>
                  <div className="hovertext">
                    <Link to="/paint-protection-films">
                      <p>
                        Your Car’s Best Defense: Paint Protection Films that
                        Shield! Give your ride the ultimate protection with
                        superior paint protection films that retain its showroom
                        brilliance.
                      </p>
                      <i className="bi bi-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whychoose4">
          <div className="container-fluid p-0 mt-6">
            <div className="row">
              <div className="title-text">
                <h2>
                  Why Choose Our{" "}
                  <span style={{ color: "#234DEF" }}>
                    Auto Detailing Services in Calgary?
                  </span>
                </h2>
              </div>
            </div>
            <div className="bg-blue">
              <div className="row mt-5 align-items-center">
                <div className="col-lg-5 col-md-12 col-sm-12 bgchose">
                  <img
                    className="img-fluid"
                    alt="best interior car detailing Calgary"
                    src="assets/img/carimgewassh.webp"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="bgshaps">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 co-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <img
                                className="img-fluid"
                                src="assets/img/icon4.webp"
                                data-aos="flip-right"
                              />
                            </div>
                            <div className="col-9">
                              <h3>Attention to Detail</h3>
                              <p>
                                We believe that the little things make a big
                                difference. That's why we pay close attention to
                                every detail of your car, from the wheels to the
                                roof, to ensure it looks flawless when you drive
                                away. Trust our auto detailing services in
                                Calgary for an unmatched experience.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 co-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <img
                                className="img-fluid"
                                src="assets/img/icon2.webp"
                                data-aos="flip-right"
                              />
                            </div>
                            <div className="col-9">
                              <h3>Experienced</h3>
                              <p>
                                With years of experience in the industry, we
                                know how to treat every type of vehicle with
                                care and attention to detail. Our car detailing
                                services in Calgary are equipped with a team of
                                technicians skilled in using the latest
                                techniques and equipment to get the job done
                                right.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-6 co-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <img
                                className="img-fluid"
                                src="assets/img/icon3.webp"
                                data-aos="flip-right"
                              />
                            </div>
                            <div className="col-9">
                              <h3>Quality Products</h3>
                              <p>
                                Our vehicle detailing company in Calgary uses
                                only the best products on your ride, ensuring
                                that your car gets the best possible care. From
                                shampoos and polishes to waxes and sealants, we
                                use only the finest products to give your
                                vehicle a showroom shine.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 co-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-3">
                              <img
                                className="img-fluid"
                                src="assets/img/icon1.webp"
                                data-aos="flip-right"
                              />
                            </div>
                            <div className="col-9">
                              <h3>Professional</h3>
                              <p>
                                Our team of experts is dedicated to delivering
                                top-notch auto detailing service in Calgary
                                ensuring your vehicle looks its best. We take
                                pride in our work and always aim to exceed your
                                expectations.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about11 mt-6 p-text-fonts">
          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="title-text col-lg-8">
                <p>Know Us</p>
                <h2>
                  About T-Bros{" "}
                  <span style={{ color: "#234DEF" }}>
                    Auto Detailing in Calgary
                  </span>
                </h2>
                <p>
                  T-Bros Auto Detailing is a business that has been founded upon
                  our combined ideas, expertise, and years of experience. Our
                  team of professionals is highly skilled and passionate about
                  providing the best sanitizing and
                  <strong> auto detailing in Calgary </strong> help our
                  customers feel proud and satisfied with their vehicles.
                </p>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <h2>
                  T Bros{" "}
                  <span style={{ color: "#234DEF" }}>
                    Auto Detailing Services{" "}
                  </span>{" "}
                  Prides on Using Only the Finest{" "}
                  <span style={{ color: "#234DEF" }}>
                    {" "}
                    Quality of Product and Equipment
                  </span>{" "}
                </h2>
                <br />
                <p>
                  At T-Bros Auto Detailing Services, quality is non-negotiable.
                  We use only the finest products and the latest equipment for
                  every detailing job. Our commitment to excellence ensures that
                  your vehicle receives the utmost care, leaving it in the best
                  condition and exceeding your expectations.
                </p>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <img
                  className="img-fluid"
                  alt="professional car detailing calgary"
                  src="assets/img/about-image.webp"
                  data-aos="fade-right"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="most-experience mt-6">
          <div className="container">
            <div className="row">
              <div className="title-text">
                <p style={{ color: "#fff" }}>Most Experienced</p>
                <h2 style={{ color: "#fff" }}>
                  Our{" "}
                  <span style={{ color: "var(--yellow)" }}>Team Strength</span>
                </h2>
              </div>
            </div>
            <div className="row row justify-content-center mt-3">
              <div className="col-lg-11 col-md-12 col-sm-12">
                <p>
                  We strongly believe that keeping your car as clean as possible
                  and protecting it is the key to maintaining its new-like
                  appearance for a longer period. Our commitment to using only
                  the highest quality products and equipment, combined with our
                  expertise in vehicle detailing services in Calgary, allows us
                  to deliver superior results that you can fully trust, without
                  any disappointments. <br />
                  <br /> With over 15 years of expertise in auto detailing
                  services, prompt customer service, and management, coupled
                  with a passion for auto detailing, we bring valuable
                  experience to the business. ē
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="link-btn">
                <Link to="/why-choose">MORE INFO</Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="rightbottom-img">
                <img
                  className="img-fluid"
                  src="assets/img/washcar1.webp"
                  data-aos="zoom-in"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="filter-img">
          <div className="container mt-6">
            <div className="row mb-5 justify-content-center">
              <div className="title-text col-lg-8">
                <p>Our Expertise</p>
                <h2>
                  The Power of{" "}
                  <span style={{ color: "#234DEF" }}>
                    Car Detailing in Calgary
                  </span>
                </h2>
                <p>
                  Our expertise lies in the power of car detailing in Calgary.
                  With precision and dedication, we transform vehicles into
                  works of art. Every detail is meticulously attended to,
                  ensuring an unparalleled standard of excellence. Experience
                  the difference as we bring out the true beauty in every
                  vehicle, showcasing the power of detailing.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-imgfilter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 co-sm-12">
                      <div id="comparison">
                        <figure className="figure1">
                          <div id="handle" />
                          <div id="divisor" />
                        </figure>
                        <input
                          type="range"
                          min={0}
                          max={100}
                          value={this.state.sliderValue}
                          onChange={this.handleSliderChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div id="comparison1">
                        <figure className="figure2">
                          <div id="handle1" />
                          <div id="divisor1" />
                        </figure>
                        <input
                          type="range"
                          min={0}
                          max={100}
                          value={this.state.slider1Value}
                          onChange={this.handleSlider1Change}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-imgfilter1" />
          </div>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  submitAppointment: (payload: any) => dispatch(submitAppointment(payload)),
});
export default connect(null, mapDispatchToProps)(Index);
